import {
  Box,
  Heading,
  Text,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionPanel,
  AccordionIcon,
  Divider,
  Stack,
} from '@chakra-ui/core';
import React, { memo, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { apiRequest } from '../../../../api/api';
import Score from '../../../../components/Community/Scores/Score';
import Loader from '../../../../components/Loader/Loader';
import AnalysedReport from '../../../../components/ReportAnalyser/AnalysedReport';
import { modules, titlesByModule } from '../../../../constants/modules';
import { sections } from '../../../../constants/sections';
import { changeToCamelCase } from '../../../../helpers/changeCase';
import MockOverallReport from '../Components/OverallReport';

function SectionWiseReports({ data, section }) {
  if (!data[section]?.length) {
    return null;
  }

  return (
    <Accordion allowMultiple mb={6}>
      <AccordionItem>
        <AccordionHeader
          bg="custom.blue"
          color="white"
          p={10}
          borderRadius="6px"
          _hover={{ bg: 'custom.blue', opacity: 0.9 }}
        >
          <Heading
            size="lg"
            textAlign="center"
            textTransform="uppercase"
            flex={1}
          >
            Details Scoring & Feedback: {section}
          </Heading>
          <AccordionIcon />
        </AccordionHeader>
        <AccordionPanel p={0} mt={6}>
          {(data[section] || []).map((item) => {
            if (
              item.module === modules.reading.FillInTheBlanksDropDown &&
              !item.options
            ) {
              return null;
            }
            return (
              <ContentBox key={item.id}>
                <Text mb={4} textTransform="uppercase" fontWeight={600}>
                  {titlesByModule[section][item.module]}
                </Text>
                <Score
                  score={{
                    details: item,
                  }}
                  module={item.module}
                  section={section}
                  isMockReport
                />
              </ContentBox>
            );
          })}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}

function MockStat({ data, section, ...props }) {
  if (!data.report?.stats?.[section]) {
    return null;
  }

  return (
    <Box {...props}>
      <Text textTransform="uppercase" fontWeight={600} mb={3}>
        {section}
      </Text>
      <Divider />
      <Stack spacing={3}>
        {data.report?.stats?.[section].map((item) => (
          <Box
            key={item.title}
            bg="custom.white6"
            py={2}
            px={4}
            borderRadius={4}
          >
            <Text color="custom.black3">
              {item.title}
              {': '}
              <Text as="span" color="custom.blue" fontWeight={600}>
                {item.average} out of {item.total}
              </Text>
            </Text>
          </Box>
        ))}
      </Stack>
    </Box>
  );
}

function MockStats({ data }) {
  return (
    <Stack spacing={6}>
      <MockStat data={data} section={sections.speaking} />
      <MockStat data={data} section={sections.writing} />
      <MockStat data={data} section={sections.reading} />
      <MockStat data={data} section={sections.listening} />
    </Stack>
  );
}

const Reports = memo(({ data }) => {
  return (
    <>
      <ContentBox>
        <MockOverallReport data={data} />
      </ContentBox>

      {data?.report?.stats && (
        <>
          <ContentBox bg="custom.blue" color="white">
            <Heading size="lg" textAlign="center" textTransform="uppercase">
              MOCK STATS
            </Heading>
          </ContentBox>

          <ContentBox>
            <MockStats data={data} />
          </ContentBox>
        </>
      )}

      {!!data?.report?.analysis?.target && (
        <>
          <ContentBox bg="custom.blue" color="white">
            <Heading size="lg" textAlign="center" textTransform="uppercase">
              MOCK REPORT ANALYSIS
            </Heading>
          </ContentBox>

          <ContentBox>
            <AnalysedReport data={data.report} isMockReport py={0} />
          </ContentBox>
        </>
      )}

      {data?.report?.answers && (
        <>
          <SectionWiseReports
            data={data.report.answers}
            section={sections.speaking}
          />

          <SectionWiseReports
            data={data.report.answers}
            section={sections.writing}
          />

          <SectionWiseReports
            data={data.report.answers}
            section={sections.reading}
          />

          <SectionWiseReports
            data={data.report.answers}
            section={sections.listening}
          />
        </>
      )}
    </>
  );
});

function ContentBox({ children, ...props }) {
  return (
    <Box
      borderRadius="6px"
      bg="white"
      p={10}
      boxShadow="custom.primary"
      mb={6}
      {...props}
    >
      {children}
    </Box>
  );
}

function MockReportPage() {
  const { id } = useParams();

  const [data, setData] = useState();

  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        setIsFetching(true);
        const { data } = await apiRequest('get', `mocks/report/${id}`);
        setData(changeToCamelCase(data));
        setIsFetching(false);
      } catch {}
    }
    fetchData();
  }, [id]);

  return (
    <Box maxWidth="950px" margin="30px auto">
      {isFetching && (
        <ContentBox>
          <Loader />{' '}
        </ContentBox>
      )}
      {!isFetching && <Reports data={data} />}
    </Box>
  );
}

export default MockReportPage;
